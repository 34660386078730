<template>
<div>
  <v-app-bar app :style="{ background: this.$vuetify.theme.dark ? '#181818' : this.systemDetails.themecolor }" tile height="62" width="100%">
    <v-row no-gutters>
      <v-col cols="6" style="max-width: 230px" class="pl-0 pb-0" v-if="listOfWidgets.selectedcharts.filter(x => x.canShow).length">
        <!-- eslint-disable-next-line -->
        <v-select outlined dense height="33" dark class="mr-2 caption" :items="widgetView" item-text="label" dense
        item-value="value" v-model="currentWidgetView" hide-details></v-select>
      </v-col>
      <v-col>
        <div class="ml-0 mt-3 d-flex">
          <v-icon :color="systemDetails.textcolor" size="24" class="mb-1"> {{ currentModule.icon }} </v-icon>
          <span :class="['body-1 mx-2 font-weight-bold']" :style="`color: ${ systemDetails.textcolor || 'white' };`">
            {{ (currentModule.name) }}
          </span>
        </div>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
    </v-row>
  </v-app-bar>
  <v-row v-if="loading" class="mt-0 px-4">
    <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-for="n in 6" :key="n" class="px-4 mt-6">
      <v-skeleton-loader ref="skeleton" type="card-avatar" :tile="false" class="mx-auto"></v-skeleton-loader>
    </v-col>
  </v-row>
  <div class="custom-dashboard mt-3" v-else>
    <v-container fluid class="pa-0">
      <template v-if="(currentWidgetView === 0 && listOfWidgets.selectedcharts.filter(x => x.canShow).length)">
        <!-- <draggable v-model="listOfWidgets.selectedcharts" tag="div" class="row" v-bind="dragOptions" @end="dragWidget"> -->
          <template v-for="(widget, index) in listOfWidgets.selectedcharts">
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" :key="widget.sortid + reInit" class="pb-0 mb-3" v-if="(currentWidgetView === 1) ? (widget.canShow && widget.isCreatedByMe) : (widget.canShow)">
              <widget
              :widget="widget"
              :createdBy="widget.isCreatedByMe ? 'me' : 'others'"
              :widgetDetails="widget.widgetDetails"
              @edit="editWidget(widget, index)"
              @remove="removeWidgetDialog = true; storedSortId = widget.sortid">
              </widget>
            </v-col>
          </template>
        <!-- </draggable> -->
      </template>
      <template v-else-if="currentWidgetView === 1 && myWidgets.selectedcharts.filter(x => x.isCreatedByMe && x.canShow).length">
        <!-- <draggable v-model="myWidgets.selectedcharts" tag="div" class="row" v-bind="dragOptions" @end="dragWidget"> -->
          <template v-for="(widget, index) in myWidgets.selectedcharts">
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" :key="widget.sortid" v-if="widget.canShow && widget.isCreatedByMe">
              <widget
              :widget="widget"
              :createdBy="'me'"
              :widgetDetails="widget.widgetDetails"
              @edit="editWidget(widget, index)"
              @remove="removeWidgetDialog = true; storedSortId = widget.sortid">
              </widget>
            </v-col>
          </template>
        <!-- </draggable> -->
      </template>
      <template v-else-if="currentWidgetView === 2 && listOfWidgets.selectedcharts.filter(x => !x.isCreatedByMe && x.canShow).length">
        <!-- <v-row class="mb-12"> -->
          <template v-for="widget in listOfWidgets.selectedcharts">
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" :key="widget.sortid + reInit" class="pb-0" v-if="widget.canShow && !widget.isCreatedByMe">
              <widget
              :widget="widget"
              :createdBy="'others'"
              :widgetDetails="widget.widgetDetails">
              </widget>
            </v-col>
          </template>
        <!-- </v-row> -->
      </template>
      <template v-else>
        <v-alert icon="mdi-information-outline" prominent text type="info" class="mt-4">
          {{ $t('widgetNotFound') }} <strong>{{ $t('widgetInfo') }}</strong>
        </v-alert>
      </template>
    </v-container>

    <v-dialog fullscreen v-model="openWidgetDialog" width="1000" persistent transition="scroll-y-reverse-transition">
      <v-card height="100%">
        <v-toolbar class="widget-toolbar-custom-height" dark :color="systemDetails.themecolor">
          <v-toolbar-title :style="{ color: systemDetails.textcolor }">{{$t('widgetConfiguration')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon :color="systemDetails.textcolor" @click="closeWidgetDialog">mdi-close-circle</v-icon>
          <!-- <v-icon class="mb-2" @click="closeUserDefinedDialog"></v-icon> -->
        </v-toolbar>
        <v-form ref="widgetDialog">
          <v-card-text style="height: calc(var(--vh, 1vh)*100 - 90px)" class="overflow-y-auto">
            <v-row class="px-3">
              <v-col cols="12" class="px-0">
                <v-text-field outlined dense hide-details :label="$t('name')" v-model="widgetDetails.name" :rules="$_requiredValidation"></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0"> {{ $t('widgetType') }} :
                <v-btn-toggle v-model="widgetDetails.dataformat" group tile color="purple" mandatory>
                  <v-btn :value="1">
                    <v-icon>mdi-chart-bar</v-icon>
                  </v-btn>
                  <v-btn :value="2">
                    <v-icon>mdi-chart-pie</v-icon>
                  </v-btn>
                  <v-btn :value="3">
                    <v-icon>mdi-table-large-plus</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" class="px-0" v-if="[1, 2].includes(widgetDetails.dataformat)">
                <v-autocomplete :items="listOfGroupbyFields" :item-text="val => $t(val.label)" item-value="_id" outlined dense hide-details :label="$t('groupBy')"
                  v-model="widgetDetails.groupby" :rules="$_requiredValidation"></v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-0" v-if="widgetDetails.dataformat === 3">
                <v-autocomplete :disabled="!listOfVisibleFields.length" multiple small-chips :items="listOfVisibleFields" :item-text="val => $t(val.label)" item-value="_id" outlined dense hide-details :label="$t('visibleFields')"
                  v-model="widgetDetails.visiblefields" :rules="$_requiredValidation" @change="checkNameField">
                  <template #no-data v-if="!widgetDetails.visiblefields">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('startTyping') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template #selection="data">
                    <draggable :id="data.index" :list="widgetDetails.visiblefields" v-bind="dragOptionsChips" :move="move" @change="change">
                      <v-chip style="margin: 2px 4px" small draggable v-model="widgetDetails.visiblefields[data.index]" :key="data.item.value" @mousedown.stop @click.stop>
                        {{ $t(data.item.label) }}
                      </v-chip>
                    </draggable>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-0" v-if="[1, 2].includes(widgetDetails.dataformat)">
                <v-autocomplete :items="fieldsShow" item-text="name" item-value="value" outlined dense hide-details :label="$t('show')"
                  v-model="widgetDetails.showvalueas" @change="widgetDetails.sumfield = null"></v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-0" v-if="([1, 2].includes(widgetDetails.dataformat)) && widgetDetails.showvalueas === 2">
                <v-autocomplete :disabled="!listOfFields.length" small-chips :items="listOfFields" :item-text="val => $t(val.label)" item-value="_id" outlined dense hide-details :label="$t('selectSumField')"
                  v-model="widgetDetails.sumfield" :rules="$_requiredValidation"></v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-0">
                {{ $t('whoCanSee') }}
                <v-radio-group column v-model="widgetDetails.visibleto" class="py-0">
                  <v-row class="py-0">
                    <v-col cols="12" style="display: flex">
                      <template v-for="n in widgetVisibility">
                        <v-radio :label="n.name" :value="n.id" :key="'radio'+n.id"></v-radio><v-spacer :key="'spacer'+n.id"></v-spacer>
                      </template>
                    </v-col>
                    <v-col cols="12" lg="6" v-if="widgetDetails.visibleto === 3">
                      <v-autocomplete label="Users" :items="userList" dense hide-details outlined item-text="name" item-value="_id" v-model="widgetDetails.userstovisible" multiple>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index <= 2" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                            <span style="font-size:13px;">{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 3" class="grey--text text-caption">
                            (+{{ widgetDetails.userstovisible.length - 3 }} others)
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="px-0">
                {{ $t('filters') }}
                <v-simple-table dense width="99%" class="wrapper-table mt-2">
                      <v-expansion-panels flat v-model="activeFilterPanel" multiple>
                        <v-expansion-panel v-for="(filter, filterIndex) in widgetDetails.conditions" :key="filterIndex" class="mb-2">
                          <v-expansion-panel-header class="pa-0 flex-row-reverse" style="border: 1px solid rgba(0, 0, 0, 0.12) !important">
                            <v-row class="ma-0" style="padding-top: 1px">
                              <v-col cols="3" class="pa-0 text-center ma-auto body-2 font-weight-bold grey--text text--darken-1">{{ $t('field') }}</v-col>
                              <v-col cols="7" class="pa-0">
                                <v-select @click.stop="" outlined :items="listOfFieldsForFilter" class="mb-1 custom-select-field" :item-text="val => $t(val.label)" item-value="_id" dense hide-details v-model="filter.field"
                                  :rules="$_requiredValidation" @change="findFieldType(filter.field, filterIndex); filter.inputValue = ''"></v-select>
                              </v-col>
                              <v-col cols="2" class="pa-0 text-end">
                                <v-btn color="secondary" text fab dark x-small class="mt-1 pb-0" @click="activeFilterPanel.pop(); widgetDetails.conditions.splice(filterIndex, 1); checkRepeatedField()"><v-icon dark>mdi-minus-circle</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="table-expansion-content">
                            <v-simple-table dense width="99%" class="dense_table bordered--table">
                              <tbody :key="filterIndex">
                                <tr>
                                  <td style="width: 28%; height: 38px" class="pl-3 caption font-weight-bold grey--text text--darken-1" id="">{{ $t('condition') }}</td>
                                  <td>
                                    <v-select v-if="filter.field" :items="filter.fieldtype === 3 ? filterItemsSelect : filter.fieldtype === 4 ? filterItemsSelect : filter.fieldtype === 13 ? filterItemsNumber : ([5, 15].includes(filter.fieldtype) ? filterItemsSelect : ([6, 16].includes(filter.fieldtype) ? filterItemsDate  : filter.fieldtype === 7 ? filterItemsTime : filterItemsDefault))" class="mb-2" :item-text="val => $t(val.text)" item-value="value" dense hide-details v-model="filter.condition"
                                      :rules="$_requiredValidation"></v-select>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="width: 28%; height: 38px" class="pl-3 caption font-weight-bold grey--text text--darken-1" id="">{{ $t('value') }}</td>
                                  <td>
                                    <template  v-if="filter.fieldtype === 3">
                                      <v-select :items="[{text: $t('true'), value: true }, {text: $t('false'), value: false }]" item-text="text" item-value="value" v-model="filter.inputValue" dense hide-details></v-select>
                                    </template>
                                    <template v-if="filter.fieldtype === 4">
                                      <template v-if="filter.default_value">
                                        <v-select :items="filter.default_value.options" item-text="label" item-value="value" v-model="filter.inputValue" dense hide-details multiple>
                                        </v-select>
                                      </template>
                                    </template>
                                    <template  v-if="filter.fieldtype === 5 && filter.condition">
                                      <template>
                                        <v-select v-if="filter.default_value.selectType === 'default'" :items="filter.default_value.options"
                                          :item-text="filter.enableoptiontranslations ? val => filter.is_internal ? $t(`${$route.params.name}_${filter.label}_option_${val.value}`) : $t(`${filter.label}_option_${val.value}`) : val => $t(val.label)"
                                          item-value="value" v-model="filter.inputValue" dense hide-details multiple>
                                          <template #selection="{ item, index }">
                                          <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                                            <span style="font-size:13px;">{{ filter.enableoptiontranslations ? filter.is_internal ? $t(`${$route.params.name}_${filter.label}_option_${item.value}`) : $t(`${filter.label}_option_${item.value}`) : item.label }}</span>
                                          </v-chip>
                                          <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ filter.inputValue.length - 1 }} others)
                                          </span>
                                        </template>
                                        </v-select>
                                        <v-autocomplete v-else-if="filter.default_value.selectType === 'module'" :items="filter.default_value.options" item-text="data.name" item-value="_id" ref="filterSearchText" v-model="filter.inputValue" hide-details
                                        @keydown="preventSpecialCharacters($event)" @keyup="$event.keyCode !== 13 ? makeLiveSearch(filter.field, filterIndex) : ''" class="pt-0" multiple>
                                        <template #selection="{ item, index }">
                                          <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                                            <span style="font-size:13px;">{{ item.data.name }}</span>
                                          </v-chip>
                                          <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ filter.inputValue.length - 1 }} others)
                                          </span>
                                        </template>
                                        </v-autocomplete>
                                        <v-text-field v-else-if="filter.default_value.selectType === 'custom'" dense hide-details v-model="filter.inputValue" :placeholder="$t('typeHere')"></v-text-field>
                                      </template>
                                    </template>
                                    <template  v-if="filter.fieldtype === 6 && filter.condition">
                                      <v-row v-if="!['today', 'thismonth', 'thisweek', 'withinnext', 'withinpast'].includes(filter.condition)">
                                        <v-col v-if="['created_at', 'modified_at', 'before', 'after'].includes(filter.condition || filter._id)">
                                          <template>
                                            <v-menu v-model="filter.datePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290" max-width="290" :key="filterIndex">
                                              <template #activator="{ on, attrs }">
                                                <v-text-field hide-details v-model="filter.date" :placeholder="$t('date')" @focusout="filter.datePicked = $formatter.formatDate(filter.date, userDetails.dateformat, 'YYYY-MM-DD')"
                                                  @focus="filter.datePicked = $formatter.formatDate(filter.date, userDetails.dateformat, 'YYYY-MM-DD')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                              </template>
                                              <v-date-picker no-title v-model="filter.datePicked" @input="filter.datePicker = false; filter.date = $formatter.formatDate(filter.datePicked, 'YYYY-MM-DD', userDetails.dateformat)" ></v-date-picker>
                                            </v-menu>
                                          </template>
                                        </v-col>
                                        <v-col v-if="filter.condition === 'between'">
                                          <template>
                                            <v-menu v-model="filter.startdatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290" max-width="290" :key="filterIndex">
                                              <template #activator="{ on, attrs }">
                                                <v-text-field hide-details v-model="filter.startdate" :placeholder="$t('startDate')" @focusout="filter.startDatePicked = $formatter.formatDate(filter.startdate, userDetails.dateformat, 'YYYY-MM-DD')"  @focus="filter.datePicked = $formatter.formatDate(filter.startdate, userDetails.dateformat, 'YYYY-MM-DD')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                              </template>
                                              <v-date-picker no-title v-model="filter.startDatePicked" @input="filter.startdatePicker = false; filter.startdate = $formatter.formatDate(filter.startDatePicked, 'YYYY-MM-DD', userDetails.dateformat)" ></v-date-picker>
                                            </v-menu>
                                          </template>
                                        </v-col>
                                        <v-col v-if="filter.condition === 'between'">
                                          <template>
                                            <v-menu v-model="filter.enddatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290" max-width="290">
                                              <template #activator="{ on, attrs }">
                                                <v-text-field hide-details v-model="filter.enddate" :placeholder="$t('endDate')" @focusout="filter.endDatePicked = $formatter.formatDate(filter.enddate, userDetails.dateformat, 'YYYY-MM-DD')"  @focus="filter.endDatePicked = $formatter.formatDate(filter.enddate, userDetails.dateformat, 'YYYY-MM-DD')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                              </template>
                                              <v-date-picker no-title v-model="filter.endDatePicked" @input="filter.enddatePicker = false; filter.enddate = $formatter.formatDate(filter.endDatePicked, 'YYYY-MM-DD', userDetails.dateformat)" ></v-date-picker>
                                            </v-menu>
                                          </template>
                                        </v-col>
                                      </v-row>
                                      <template v-if="filter.condition === 'withinnext' || filter.condition === 'withinpast'">
                                        <v-text-field dense hide-details :placeholder="$t('Days')" v-model="filter.within" suffix="days"></v-text-field>
                                      </template>
                                    </template>
                                    <template v-else-if="filter.fieldtype === 7 && filter.condition">
                                      <v-row>
                                        <template v-if="filter.condition === 'between'">
                                          <v-col class="py-0 pt-2" cols="6" >
                                            <v-text-field dense hide-details slot="activator" v-model="filter.start" placeholder="HH:mm">
                                              <template #append>
                                                <v-dialog v-model="filter.startTimeMenu" max-width="320">
                                                  <template #activator="{ on, attrs }">
                                                    <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                                  </template>
                                                  <v-time-picker color="primary" v-model="filter.start" format="24hr" @click:minute="filter.startTimeMenu = false" full-width></v-time-picker>
                                                </v-dialog>
                                              </template>
                                            </v-text-field>
                                          </v-col>
                                          <v-col class="py-0 pt-2" cols="6">
                                            <v-text-field dense hide-details slot="activator" v-model="filter.end" placeholder="HH:mm">
                                              <template #append>
                                                <v-dialog v-model="filter.endTimeMenu" max-width="320">
                                                  <template #activator="{ on, attrs }">
                                                    <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                                  </template>
                                                  <v-time-picker color="primary" v-model="filter.end" format="24hr" @click:minute="filter.endTimeMenu = false" full-width></v-time-picker>
                                                </v-dialog>
                                              </template>
                                            </v-text-field>
                                          </v-col>
                                        </template>
                                        <template v-else>
                                          <v-text-field dense hide-details slot="activator" v-model="filter.inputValue" placeholder="HH:mm" key="timepicker">
                                            <template #append>
                                              <v-dialog v-model="filter.timeMenu" max-width="320">
                                                <template #activator="{ on, attrs }">
                                                  <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                                </template>
                                                <v-time-picker color="primary" v-model="filter.inputValue" format="24hr" @click:minute="filter.timeMenu = false" full-width></v-time-picker>
                                              </v-dialog>
                                            </template>
                                          </v-text-field>
                                        </template>
                                      </v-row>
                                    </template>
                                    <template v-else-if="filter.fieldtype === 16 && filter.condition">
                                      <v-row>
                                        <v-col v-if="['created_at', 'modified_at', 'before', 'after'].includes(filter.condition)" cols="12">
                                          <datetime-picker v-model="filter.date" @input="updateDateTime(filterIndex)"></datetime-picker>
                                        </v-col>
                                        <v-col v-else-if="filter.condition === 'between'" cols="12">
                                          <v-layout row align-center class="ma-0" key="between">
                                            <v-flex xs6 class="pr-2">
                                              <datetime-picker @updateStartEndDate="updateStartEndDate(filterIndex)" v-model="filter.startdate" key="startDateTime"></datetime-picker>
                                            </v-flex>
                                            <v-flex xs6 class="pl-2">
                                              <datetime-picker @updateStartEndDate="updateStartEndDate(filterIndex)" v-model="filter.enddate" key="endDateTime" enddatetime></datetime-picker>
                                            </v-flex>
                                          </v-layout>
                                        </v-col>
                                        <v-col v-if="['withinnext', 'withinpast'].includes(filter.condition)" class="py-0" cols="12">
                                          <v-text-field dense :placeholder="$t('days')" v-model="filter.within" @keydown="preventSpecialCharacters($event)" suffix="days" class="caption pt-2" :rules="$_requiredValidation"></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </template>
                                    <v-text-field dense hide-details v-if="filter.condition && ![5, 15, 6, 16, 7, 3, 4].includes(filter.fieldtype)" :placeholder="$t('typeHere')" v-model="filter.inputValue" ></v-text-field>
                                    <v-select :items="listOfUsers.length ? listOfUsers : getListOfUsers({ filterIndex })" item-text="name" item-value="_id" v-model="filter.inputValue" @change="changeHandler({ filterIndex })" dense hide-details v-if="filter.fieldtype === 15" multiple>
                                      <template v-slot:selection="{ item, index }">
                                          <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                                            <span style="font-size:13px;">{{ item.name }}</span>
                                          </v-chip>
                                          <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ filter.inputValue.length - 1 }} others)
                                          </span>
                                        </template>
                                    </v-select>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-simple-table>
                <v-flex class="text-start">
                  <v-btn color="success" x-small fab class="mt-2 mb-0 pb-0" @click="addFilterItem" :disabled="listOfFields.length === 0"><v-icon>mdi-plus</v-icon></v-btn>
                </v-flex>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center" style="height: 44px">
            <v-btn color="success" :loading="loading" @click="saveWidget(storedSortId)">{{`${widgetDetails.sortid ? $t('update'): $t('save')}`}}</v-btn>
            <v-btn color="error" @click="closeWidgetDialog">{{$t('close')}}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeWidgetDialog" width="400" persistent>
      <v-card>
        <v-toolbar dark :color="systemDetails.themecolor" class="mb-2" style="height:50px">
          <v-toolbar-title class="pb-4" :style="{ color: systemDetails.textcolor }">{{$t('confirmation')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-3">
          <span class="subtitle-1">{{ $t('removeWidgetMsg') }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="error" @click="removeWidget(storedSortId)">{{$t('yes')}}</v-btn>
          <v-btn color="success" @click="closeRemoveWidgetDialog">{{$t('no')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial fixed bottom right class="mb-12" v-if="currentWidgetView !== 2">
      <template v-slot:activator>
        <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" fab dark
        :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }" @click="openWidgetDialog = true; widgetDetails.userstovisible = [userDetails._id]">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <v-speed-dial fixed top left class="switch-list-btn mt-12">
      <template v-slot:activator>
        <v-btn x-small fab dark :color="systemDetails.themecolor" class="mt-3 ml-2" @click="$emit('setCurrentView', { name: 'list', value: 1 })">
          <v-icon :color="systemDetails.textcolor">mdi-format-list-bulleted</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</div>

</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import WidgetFunctions from '@/views/WidgetsDashboard/mixin'
export default {
  mixins: [WidgetFunctions],
  props: ['currentModule'],
  data () {
    return {
      loading: false,
      currentWidgetView: 0,
      listOfWidgets: {
        selectedcharts: []
      },
      openWidgetDialog: false,
      widgetDetails: {
        id: 5,
        conditions: [],
        dataformat: 1,
        showvalueas: 1,
        visibleto: 1,
        userstovisible: []
      },
      listOfFields: [],
      listOfFieldsForFilter: [],
      fieldsShow: [
        { name: 'showCount', value: 1 },
        { name: 'showSum', value: 2 }
      ],
      listOfVisibleFields: [],
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      },
      userDefinedWidgetList: [],
      storedSortId: null,
      removeWidgetDialog: false,
      widgetsOrderBy: [],
      reInit: 0,
      activeFilterPanel: [],
      listOfUsers: [],
      myWidgets: {
        selectedcharts: []
      }
    }
  },
  components: {
    draggable,
    widget: () => import('@/views/WidgetsDashboard/Widget')
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'getUsers', 'systemDetails']),
    widgetsCount () {
      return (this.listOfWidgets && this.listOfWidgets.selectedcharts && this.listOfWidgets.selectedcharts.length) ? ((this.currentWidgetView === 0) ? { name: this.$t('allWidget'), total: this.listOfWidgets.selectedcharts.filter(x => x.canShow).length }
        : (this.currentWidgetView === 1) ? { name: this.$t('widgetCreatedByMe'), total: this.listOfWidgets.selectedcharts.filter(x => x.canShow && x.isCreatedByMe).length }
          : { name: this.$t('otherUsersWidgets'), total: this.listOfWidgets.selectedcharts.filter(x => x.canShow && !x.isCreatedByMe).length }) : { name: this.$t('allWidget'), total: 0 }
    },
    widgetView () {
      return [
        { label: this.$t('all'), value: 0 },
        { label: this.$t('widgetCreatedByMe'), value: 1 },
        { label: this.$t('otherUsersWidgets'), value: 2 }
      ]
    },
    dragOptions () {
      return {
        animation: 0,
        disabled: !!this.$vuetify.breakpoint.mdAndDown
      }
    },
    dragOptionsChips () {
      return {
        animation: 200,
        group: 'group',
        disabled: false,
        ghostClass: 'ghost',
        sort: true
      }
    },
    widgetVisibility () {
      return [
        { id: 1, name: this.$t('onlyMe') },
        { id: 2, name: this.$t('anyone') },
        { id: 3, name: this.$t('selectedUsers') }
      ]
    },
    userList () {
      const users = this.$formatter.cloneVariable(this.getUsers)
      const index = users.findIndex(el => el._id === this.userDetails._id)
      users[index].disabled = true
      return users
    },
    listOfGroupbyFields () {
      return this.$formatter.cloneVariable(this.listOfFields.filter(x => x.name !== 'isanonymous' && ![1, 2, 7, 12, 13, 14, 16, 18].includes(x.type) && !(x.type === 5 && (JSON.parse(x.default_value).is_multiselect || JSON.parse(x.default_value).selectType === 'module' || JSON.parse(x.default_value).selectType === 'custom')) && !(x.type === 15 && (JSON.parse(x.default_value).is_multiselect))))
    }
  },
  created () {
    this.getAllWidgets()
  },
  watch: {
    'widgetDetails.userstovisible' (val) {
      if (val && !val.includes(this.userDetails._id)) {
        val.unshift(this.userDetails._id)
      }
    }
  },
  methods: {
    getListOfUsers ({ filterIndex }) {
      const listOfusers = [
        { name: this.$t('currentUser'), _id: 'currentUser' },
        ...this.getUsers
      ]

      const result = (this.widgetDetails.conditions[filterIndex] && this.widgetDetails.conditions[filterIndex].inputValue && this.widgetDetails.conditions[filterIndex].inputValue.length)
        ? this.widgetDetails.conditions[filterIndex].inputValue.includes('currentUser')
          ? listOfusers.map((item) => ({ ...item, disabled: (item._id !== 'currentUser') }))
          : listOfusers.map((item) => ({ ...item, disabled: (item._id === 'currentUser') }))
        : listOfusers

      return result
    },
    changeHandler ({ filterIndex }) {
      this.listOfUsers = this.getListOfUsers({ filterIndex })
      if (this.widgetDetails.conditions[filterIndex] && this.widgetDetails.conditions[filterIndex].inputValue && this.widgetDetails.conditions[filterIndex].inputValue.length) {
        if (this.widgetDetails.conditions[filterIndex].inputValue.includes('currentUser')) {
          this.widgetDetails.conditions[filterIndex].inputValue = ['currentUser']
          this.widgetDetails.conditions[filterIndex].is_current_user = true
        }
        return
      }
      if (this.widgetDetails.conditions[filterIndex]) this.widgetDetails.conditions[filterIndex].is_current_user = false
    },
    editWidget (widget) {
      this.storedSortId = widget.sortid
      this.widgetDetails = { ...this.$formatter.cloneVariable(widget) }
      if (this.widgetDetails.conditions && this.widgetDetails.conditions.length) {
        this.widgetDetails.conditions.forEach(condition => {
          if ([6, 16].includes(condition.fieldtype)) {
            for (const i in condition) {
              if (condition.fieldtype === 6 && ['date', 'startdate', 'enddate'].includes(i)) {
                condition[i] = this.$formatter.formatDate(condition[i], 'DD.MM.YYYY', this.userDetails.dateformat)
              }
              // else if (['date', 'startdate', 'enddate'].includes(i)) condition[i] = this.$formatter.formatDate(condition[i], 'DD.MM.YYYY HH:mm', this.userDetails.dateformat)
            }
          }
        })
      }
      this.openWidgetDialog = true
      this.checkRepeatedField()
    },
    removeWidget (sortid) {
      let charts = null
      if (this.currentWidgetView === 0) charts = this.$formatter.cloneVariable(this.listOfWidgets.selectedcharts)
      else charts = this.$formatter.cloneVariable(this.listOfWidgets.selectedcharts.filter(x => x.isCreatedByMe))
      const index = charts.findIndex(x => x.sortid === sortid)
      charts.splice(index, 1)
      const model = {
        user_id: this.userDetails._id,
        module_id: this.$route.params.id,
        orderby: []
      }
      model.selectedcharts = charts.filter(x => x.isCreatedByMe)
      this.$api.execute('post', 'dashboardmodule/save_list_view_widget', model)
        .then(() => {
          this.closeRemoveWidgetDialog()
          const idx1 = this.listOfWidgets.selectedcharts.findIndex(x => x.sortid === sortid)
          const idx2 = this.myWidgets.selectedcharts.findIndex(x => x.sortid === sortid)
          this.listOfWidgets.selectedcharts.splice(idx1, 1)
          this.myWidgets.selectedcharts.splice(idx2, 1)
        })
    },
    closeRemoveWidgetDialog () {
      this.removeWidgetDialog = false
      this.storedSortId = null
    },
    addFilterItem () {
      if (!this.widgetDetails.conditions.length || this.widgetDetails.conditions[this.widgetDetails.conditions.length - 1].field) this.widgetDetails.conditions.push({})
    },
    saveWidget (sortid) {
      const widget = this.$formatter.cloneVariable(this.widgetDetails)
      if (!widget.groupby) widget.groupby = null
      widget.conditions.forEach(condition => {
        condition.value = {}
        for (const i in condition) {
          if (!['field', 'condition', 'fieldtype', 'value'].includes(i)) condition.value[i] = condition[i]
          if (condition.fieldtype === 6 && ['date', 'startdate', 'enddate'].includes(i)) condition.value[i] = this.$formatter.formatDate(condition[i], this.userDetails.dateformat, 'DD.MM.YYYY')
        }
        condition.value = JSON.stringify(condition.value)
      })
      if (this.$refs.widgetDialog.validate()) {
        const model = {
          user_id: this.userDetails._id,
          module_id: this.$route.params.id,
          orderby: []
        }
        let charts = null
        if (this.currentWidgetView === 0) charts = this.$formatter.cloneVariable(this.listOfWidgets.selectedcharts.filter(x => x.isCreatedByMe))
        else charts = this.$formatter.cloneVariable(this.myWidgets.selectedcharts.filter(x => x.isCreatedByMe))
        const index = (sortid) ? charts.findIndex(x => x.sortid === sortid) : null
        if (index !== null) charts.splice(index, 1, { ...widget })
        else charts = (charts && charts.length) ? [...charts, ...[widget]] : [{ ...widget }]
        this.sortWidgetsByOrder(this.listOfWidgets.selectedcharts, false)
        model.selectedcharts = charts
        model.orderby = this.widgetsOrderBy
        this.$api.execute('post', 'dashboardmodule/save_list_view_widget', model)
          .then(({ data }) => {
            if (index !== null) {
              this.listOfWidgets.selectedcharts.splice(this.listOfWidgets.selectedcharts.findIndex(x => x.sortid === sortid), 1, this.setWidgetDatas(data.selectedcharts.find(x => x.sortid === sortid), this.userDetails._id))
              this.myWidgets.selectedcharts.splice(this.myWidgets.selectedcharts.findIndex(x => x.sortid === sortid), 1, this.setWidgetDatas(data.selectedcharts.find(x => x.sortid === sortid), this.userDetails._id))
            } else {
              this.listOfWidgets.selectedcharts.push(this.setWidgetDatas(data.selectedcharts[data.selectedcharts.length - 1], this.userDetails._id))
              this.myWidgets.selectedcharts.push(this.listOfWidgets.selectedcharts[this.listOfWidgets.selectedcharts.length - 1])
            }
            this.getUserDefinedData()
          })
          .finally(() => {
            this.closeWidgetDialog()
          })
      }
    },
    dragWidget () {
      const model = {
        user_id: this.userDetails._id,
        module_id: this.$route.params.id
      }
      // let charts = this.$formatter.cloneVariable(this.listOfWidgets.selectedcharts)
      model.selectedcharts = this.myWidgets.selectedcharts
      this.sortWidgetsByOrder(this.listOfWidgets.selectedcharts, false)
      model.orderby = this.widgetsOrderBy
      this.$api.execute('post', 'dashboardmodule/save_list_view_widget', model)
    },
    closeWidgetDialog () {
      this.$refs.widgetDialog.resetValidation()
      this.openWidgetDialog = false
      this.widgetDetails = {
        id: 5,
        conditions: [],
        dataformat: 1,
        showvalueas: 1,
        visibleto: 1,
        userstovisible: []
      }
      this.storedSortId = null
    },
    checkNameField () {
      const nameField = this.listOfFields.find((field) => field.name === 'name')
      if (!nameField) return
      if (this.widgetDetails.visiblefields.length && this.widgetDetails.visiblefields.includes(nameField._id)) {
        const index = this.widgetDetails.visiblefields.indexOf(nameField._id)
        this.widgetDetails.visiblefields.splice(index, 1)
        this.widgetDetails.visiblefields.unshift(nameField._id)
      }
    },
    move (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex
      }
    },
    change (value) {
      if (value.removed) {
        // insert
        this.widgetDetails.visiblefields.splice(this.dragged.to + this.dragged.newIndex, 0, this.widgetDetails.visiblefields[this.dragged.from])
        // delete
        if (this.dragged.from < this.dragged.to) this.widgetDetails.visiblefields.splice(this.dragged.from, 1) // LTR
        else this.widgetDetails.visiblefields.splice(this.dragged.from + 1, 1) // RTL
        const nameField = this.listOfVisibleFields.find(x => x.name === 'name')
        if (nameField) {
          const index = this.widgetDetails.visiblefields.findIndex(x => x === nameField._id)
          if (index > 0) {
            this.widgetDetails.visiblefields.splice(index, 1)
            this.widgetDetails.visiblefields.unshift(nameField._id)
          }
        }
      }
    },
    getAllWidgets () {
      this.loading = true
      this.$api.execute('get', `dashboardmodule/get_module_dashboard/${this.$route.params.id}`)
        .then(({ data }) => {
          if (data && data.length) {
            data.forEach(user => {
              if (user.selectedcharts && user.selectedcharts.length) {
                user.selectedcharts.forEach(widget => {
                  widget = this.setWidgetDatas(widget, user.user_id)
                })
              }
            })
            const charts = data.map(x => x.selectedcharts).flat()
            this.listOfWidgets = this.$formatter.cloneVariable(data[0])
            this.listOfWidgets.selectedcharts = (charts && charts.length) ? charts : []
            this.myWidgets.selectedcharts = (charts && charts.length) ? charts : []
            if (data.find(x => x.user_id === this.userDetails._id)) this.myWidgets = this.$formatter.cloneVariable(data.find(x => x.user_id === this.userDetails._id))
          }
        }).finally(() => {
          this.getUserDefinedData()
          this.loading = false
        })
    },
    getTableData (model, widget) {
      let currentWidget = null
      if (this.currentWidgetView === 1) currentWidget = this.$formatter.cloneVariable(this.myWidgets)
      else currentWidget = this.$formatter.cloneVariable(this.listOfWidgets)
      const index = currentWidget.selectedcharts.findIndex(x => x.sortid === widget.sortid)
      currentWidget.selectedcharts[index].widgetDetails.loader = true
      if (this.currentWidgetView === 1) this.myWidgets = Object.assign({}, this.listOfWidgets, currentWidget)
      else this.listOfWidgets = Object.assign({}, this.listOfWidgets, currentWidget)
      this.reInit += 1
      this.$api.execute('post', `dashboardmodule/get_user_defined_table_dashboards/${this.$route.params.id}`, model)
        .then((response) => {
          const data = response && response.data ? response.data : []
          currentWidget.selectedcharts[index].widgetDetails.pagination = this.$formatter.cloneVariable(model)
          const result = (data.find(x => x.userid === widget.user_id) && data.find(x => x.userid === widget.user_id).userdefineddashboardresults && data.find(x => x.userid === widget.user_id).userdefineddashboardresults[0]) ? data.find(x => x.userid === widget.user_id).userdefineddashboardresults[0] : null
          if (result && result.dataset && result.dataset[0] && result.dataset[0].data) currentWidget.selectedcharts[index].widgetDetails.listData = this.constructTableData(widget, result.dataset[0].data, widget.fields, true)
          currentWidget.selectedcharts[index].widgetDetails.loader = false
          if (this.currentWidgetView === 1) this.myWidgets = Object.assign({}, this.listOfWidgets, currentWidget)
          else this.listOfWidgets = Object.assign({}, this.listOfWidgets, currentWidget)
          this.reInit += 1
        })
    },
    async getUserDefinedData () {
      const model = { descending: true, page: 1, rowsPerPage: 5, sortBy: '', search: '', skip: 0, limit: 5 }
      const moduleFields = await this.getModuleFields({ module: this.$route.params.id })
      this.$api.execute('post', `dashboardmodule/get_user_defined_dashboards/${this.$route.params.id}`, model)
        .then(({ data }) => {
          this.loading = false
          this.userDefinedWidgetList = data
          this.listOfFields = moduleFields.fields
          this.listOfFieldsForFilter = moduleFields.fields.filter(x => x.name !== 'isanonymous')
          if (this.widgetDetails.visiblefields && this.widgetDetails.visiblefields.length) {
            this.listOfVisibleFields = this.$formatter.cloneVariable(this.sortFieldsByVisibleFields(this.widgetDetails.visiblefields, moduleFields.fields))
          } else this.listOfVisibleFields = this.$formatter.cloneVariable(moduleFields.fields)
          this.myWidgets.selectedcharts = this.$formatter.cloneVariable(this.constructUserDefinedWidget(this.myWidgets.selectedcharts, [moduleFields], true).filter(x => x.user_id === this.userDetails._id))
          const charts = this.sortWidgetsByOrder(this.constructUserDefinedWidget(this.listOfWidgets.selectedcharts, [moduleFields], true), (this.listOfWidgets.orderby && this.listOfWidgets.orderby.length) ? this.listOfWidgets.orderby : null)
          this.listOfWidgets.selectedcharts = (charts && charts.length) ? this.$formatter.cloneVariable(charts) : []
        })
    },
    setWidgetDatas (widget, userid) {
      widget.canShow = true
      widget.isCreatedByMe = userid === this.userDetails._id
      widget.user_id = userid
      const data = this.getUsers.find(x => x._id === userid)
      widget.createdBy = (data && data.name) ? data.name : (data && data.firstname && data.lastname) ? data.firstname + data.lastname : ''
      widget = this.checkWidgetPermissions(widget)
      widget.widgetDetails = (widget.id === 1) ? { ticketsLoader: true, ticketsChartLoader: true } : (widget.id === 5) ? { loader: true, pagination: this.pagination } : { loader: true }
      return widget
    },
    checkWidgetPermissions (widget) {
      if (widget.id === 1 || widget.id === 8) if (!this.hasTicketPermission) widget.canShow = false
      if (widget.id === 2 || widget.id === 3) widget.canShow = this.userDetails.issharepointuser
      if (widget.id === 5) {
        widget.conditions.forEach(x => {
          const value = JSON.parse(x.value)
          for (const i in value) { x[i] = value[i] }
          delete widget.conditions.value
        })
      }
      return widget
    },
    sortWidgetsByOrder (selectedCharts, orderby) {
      if (selectedCharts && selectedCharts.length) {
        let charts = []
        if (orderby && orderby.length) {
          orderby.forEach((widget, index) => {
            const fieldItem = selectedCharts.find((x) => x.sortid === widget)
            if (fieldItem) charts.push(fieldItem)
            // else orderby.splice(index, 1)
          })
          charts = [...charts, ...selectedCharts.filter((x) => !orderby.includes(x.sortid))]
        } else {
          orderby = selectedCharts.map(x => x.sortid)
          charts = selectedCharts
        }
        this.widgetsOrderBy = orderby
        return charts
      }
    },
    findFieldType (id, index) {
      this.checkRepeatedField()
      if (![this.CREATED_BY, this.CREATED_AT, this.MODIFIED_BY, this.MODIFIED_AT].includes(id)) {
        if (this.widgetDetails.conditions[index].filter) this.widgetDetails.conditions[index].filter = null
        const result = this.listOfFields.find(x => x._id === id)
        this.widgetDetails.conditions[index].fieldtype = result.type
        if (result.type === 5) {
          this.widgetDetails.conditions[index].enableoptiontranslations = result.enableoptiontranslations
          this.widgetDetails.conditions[index].label = result.label
        }
        if (result && JSON.parse(result.default_value)) this.widgetDetails.conditions[index].default_value = JSON.parse(result.default_value)
        this.widgetDetails.conditions[index].is_internal = result.is_internal
      } else {
        if ([this.CREATED_BY, this.MODIFIED_BY].includes(id)) this.widgetDetails.conditions[index].fieldtype = 15
        else this.widgetDetails.conditions[index].fieldtype = 16
      }
    },
    checkRepeatedField () {
      if (this.widgetDetails && this.widgetDetails.conditions) {
        const filterFields = this.widgetDetails.conditions.map(x => x.field)
        this.listOfFieldsForFilter = this.listOfFieldsForFilter.map(x => {
          if (filterFields.includes(x._id)) x.disabled = true
          else x.disabled = false
          return x
        })
      }
      this.listOfUsers = []
    }
  }
}
</script>

<style>
.switch-list-btn {
  top: 50px !important;
  left: -8px !important;
}
.table-expansion-content .v-expansion-panel-content__wrap{
  padding: 0px !important;
}
.table-expansion-content .bordered--table table thead th, .bordered--table table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.wrapper-table > .v-data-table__wrapper > table{
  width: 99% !important;
}
.wrapper-table fieldset{
  top: 3px !important;
  bottom: 5px !important;
}
.wrapper-table .v-expansion-panel-header{
  min-height: 40px !important;
  height: 40px !important;
}
.wrapper-table .v-input__append-inner {
  margin-top: 10px !important;
}
.wrapper-table .v-expansion-panel-header__icon{
  padding-left: 8px;
}
.dense_table tbody{
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
header.widget-toolbar-custom-height, header.widget-toolbar-custom-height > div.v-toolbar__content  {
  height: 45px !important;
}
</style>
